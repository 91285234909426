import {
  applyPolyfills,
  defineCustomElements
} from '@postnord/web-components/loader'
import axios from 'axios'
import { createApp } from 'vue'
import { createRouter } from './router/index.js'
import store from './store'

import PnRetailBackofficeComponents from '@postnord/retail-backoffice-components/dist'
import VueTelInput from 'vue-tel-input'
import App from './App.vue'
import i18n from './i18n/i18n.js'

import 'pn-design-assets/pn-assets/styles/pn-styles.scss'
import 'flag-icon-css/css/flag-icons.min.css'
import 'vue-tel-input/dist/vue-tel-input.css'

applyPolyfills().then(() => {
  defineCustomElements()
})

axios.defaults.validateStatus = function (status) {
  return status < 500
}

const router = createRouter()
const app = createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(PnRetailBackofficeComponents)
  .use(VueTelInput)

app.mount('#app')
