import {
  getRetailItems,
  getSpItems
} from '@/shared/components/SideMenu/services'
import { getTokenProps } from '@/shared/components/SideMenu/utils'
import { ref, watchEffect } from 'vue'
import { useStore } from 'vuex'

export function useFeatureFilter() {
  const store = useStore()
  const filteredFeatures = ref([])
  const isCompensationEnabled = store.getters['features/isCompensationEnabled']
  const filterOutDisabledFeatures = (features) => {
    return features.filter((item) => {
      if (item.meta.id !== 'compensationmanagement') return true
      return isCompensationEnabled
    })
  }

  watchEffect(async () => {
    const isRetailLoggedIn = store.getters['retail/isLoggedIn']
    const isUnifiedLoggedIn = store.getters['unified/isLoggedIn']
    const permission = store.state.unified.permission
    let capability = store?.state?.retail?.capability
    const { azure_usage_location } = getTokenProps() || {}
    const market = azure_usage_location || store.getters['retail/currentMarket']

    if (isRetailLoggedIn) {
      if (capability === null) {
        await store.dispatch('retail/getCapability')
        capability = store.state.retail.capability
      }
      filteredFeatures.value = await getSpItems(capability, market)
    } else if (isUnifiedLoggedIn) {
      const items = await getRetailItems(store, permission, market)
      const enabledFeatures = filterOutDisabledFeatures(items)
      filteredFeatures.value = enabledFeatures
    }
  })

  return { filteredFeatures }
}
